export const memberCategories = [
  { id: 1, catSlug: "kitei", catName: "中国ビジネス法規集" },
  { id: 2, catSlug: "kaihou", catName: "会報" },
  { id: 3, catSlug: "vnkaihou", catName: "ベトナム会報" },
  { id: 4, catSlug: "sokuhou", catName: "中国最新法規速報(ワンポイント解説)" },
  { id: 5, catSlug: "survey", catName: "賃金改定調査報告" },
  {
    id: 6,
    catSlug: "manual",
    catName: "個人情報越境提供基準契約届出マニュアル",
  },
];

export const freeCategories = [
  { id: 1, catSlug: "sokuhou-free", catName: "中国最新法規速報(法律名URL)" },
  {
    id: 2,
    catSlug: "magbacknumber",
    catName: "メルマガバックナンバー 中国ビジネス版",
  },
  {
    id: 3,
    catSlug: "cnvnmag",
    catName: "メルマガバックナンバー 中国・ベトナムビジネス版",
  },
  {
    id: 4,
    catSlug: "onepoint",
    catName: "会計処理・税務申告実務ワンポイントガイド",
  },
  { id: 5, catSlug: "sosikihenkou", catName: "設立撤退組織再編" },
  { id: 6, catSlug: "kakouboueki", catName: "加工貿易関連" },
  { id: 7, catSlug: "hozeiku", catName: "保税開発区関連" },
  { id: 8, catSlug: "shotokuzei", catName: "個人所得税" },
  { id: 9, catSlug: "hkch", catName: "香港と中国本土関連" },
  { id: 10, catSlug: "gaikakanri", catName: "外貨管理関連" },
  { id: 11, catSlug: "inttax", catName: "国際課税関連" },
  { id: 12, catSlug: "crossborder", catName: "クロスボーダー人民元決済" },
  { id: 13, catSlug: "zeikan", catName: "貿易・税関関連" },
  { id: 14, catSlug: "chemicals", catName: "危険化学品関連" },
  { id: 15, catSlug: "kaikei", catName: "会計" },
  { id: 16, catSlug: "houseido", catName: "法制度" },
  { id: 17, catSlug: "seminar", catName: "セミナー動画" },
];

export const news = {
  id: 1,
  catSlug: "news",
  catName: "ニュース",
};
