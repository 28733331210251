import React from "react";
import { Link } from "gatsby";
import Logo from "../images/logo";
import { MenuIcon, XCircleIcon, LogoutIcon } from "@heroicons/react/solid";
import { Popover } from "@headlessui/react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useAccount,
} from "@azure/msal-react";
import { loginRequest } from "../authConfig";

const menues = [
  {
    name: "会社概要",
    href: "/aboutus/about",
  },
  {
    name: "サービス案内",
    href: "/service",
  },
  {
    name: "ニュース",
    href: "/#news",
  },
  {
    name: "コンテンツ",
    href: "/#recentPosts",
  },
  {
    name: "お問い合わせ",
    href: "/contact",
  },
];

const Header = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  return (
    <header className="border-b-4 border-cyan-800 text-sm md:text-base font-serif font-medium tracking-wide">
      <nav className="container mx-auto">
        <Popover>
          {({ open }) => (
            <>
              <div className="flex items-center justify-between p-3 md:p-5">
                <Link to="/">
                  <Logo width={160} height={60} />
                </Link>
                <div className="hidden md:inline-flex md:gap-5 tracking-wider">
                  {menues.map((menu, index) => (
                    <Link
                      key={index}
                      to={`${menu.href}`}
                      className="hover:text-blue-500"
                      activeClassName="text-cyan-600"
                    >
                      {menu.name}
                    </Link>
                  ))}
                </div>
                <div className="inline-flex items-center md:gap-5 gap-3">
                  <AuthenticatedTemplate>
                    {account ? `${account.name}様` : ""}
                    <button
                      className="border rounded-sm p-1 bg-gray-50 hover:bg-gray-100 text-gray-600"
                      onClick={() => instance.logout()}
                    >
                      <LogoutIcon className="w-4 h-4" />
                    </button>
                  </AuthenticatedTemplate>
                  <UnauthenticatedTemplate>
                    <button
                      className="border rounded-sm md:px-4 md:py-2 px-2 py-1 font-medium border-cyan-500 bg-cyan-50 hover:bg-cyan-100"
                      onClick={() => instance.loginPopup(loginRequest)}
                    >
                      会員ログイン
                    </button>
                  </UnauthenticatedTemplate>
                  <Popover.Button
                    className="md:hidden h-7 w-7 relative"
                    aria-label="Menu"
                  >
                    <MenuIcon
                      className={`h-7 w-7 text-cyan-800 transition duration-300 absolute top-0 left-0 ${
                        open ? "opacity-0" : "opacity-100"
                      }`}
                    />
                    <XCircleIcon
                      className={`h-7 w-7 text-cyan-800 transition duration-300 absolute top-0 left-0 ${
                        open ? "opacity-100" : "opacity-0"
                      }`}
                    />
                  </Popover.Button>
                </div>
              </div>
              <Popover.Panel className="absolute z-10 w-full">
                <div className="flex flex-col text-center bg-cyan-800 text-white">
                  {menues.map((menu, index) => (
                    <Link
                      key={index}
                      to={`${menu.href}`}
                      className="p-5 hover:text-cyan-100 border-b-2 border-white"
                      activeClassName="bg-cyan-700"
                    >
                      {menu.name}
                    </Link>
                  ))}
                </div>
              </Popover.Panel>
            </>
          )}
        </Popover>
      </nav>
    </header>
  );
};

export default Header;
