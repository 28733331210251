import React from "react";
import { freeCategories, memberCategories } from "./category";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";

const Icp = () => {
  return (
    <div>
      <a
        className="text-sm text-blue-300 hover:text-blue-200"
        href="https://beian.miit.gov.cn/"
        target="_blank"
        rel="noreferrer"
      >
        沪ICP备2022025979号
      </a>
    </div>
  );
};

const Footer = () => {
  const year = new Date().getFullYear();
  const links = [
    {
      title: "水野真澄コンサルティング日常記",
      url: "https://mizuno.chasechina.jp",
    },
    {
      title: "チェイス・ネクスト",
      url: "https://chasechina.jp",
    },
  ];
  const location = useLocation();

  return (
    <footer>
      <div className="py-10 md:py-20 px-5 text-center font-sans flex flex-col gap-y-3 bg-cyan-900 text-white text-sm md:text-base">
        <div className="container mx-auto max-w-4xl flex justify-between text-left">
          <div className="w-full grid grid-cols-1 md:grid-cols-5 gap-5">
            <div>
              <ul className="space-y-1">
                <li>
                  <Link
                    to="/aboutus/about"
                    className="flex items-center hover:text-blue-500"
                  >
                    会社概要
                  </Link>
                </li>
                <li>
                  <Link
                    to="/service"
                    className="flex items-center hover:text-blue-500"
                  >
                    サービス案内
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    className="flex items-center hover:text-blue-500"
                  >
                    お問い合わせ
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-span-2">
              <div className="text-base md:text-lg mb-3">無料コンテンツ</div>
              <ul className="space-y-1">
                {freeCategories.map((c, index) => (
                  <li key={index}>
                    <Link
                      to={`/${c.catSlug}/`}
                      className="flex items-center hover:text-blue-500"
                    >
                      <ChevronRightIcon className="w-4 h-4 mr-1" />
                      {c.catName}
                    </Link>
                  </li>
                ))}
                <li>
                  <Link
                    to="/mag"
                    className="flex items-center hover:text-blue-500"
                  >
                    <ChevronRightIcon className="w-4 h-4 mr-1" />
                    無料メールマガジン登録のご案内
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-span-2">
              <div className="text-base md:text-lg mb-3">有料コンテンツ</div>
              <ul className="space-y-1">
                <li>
                  <Link
                    to="/videos"
                    className="flex items-center hover:text-blue-500"
                  >
                    <ChevronRightIcon className="w-4 h-4 mr-1" />
                    中国ビジネス解説動画
                  </Link>
                </li>
                {memberCategories.map((c, index) => (
                  <li key={index}>
                    <Link
                      to={`/${c.catSlug}/`}
                      className="flex items-center hover:text-blue-500"
                    >
                      <ChevronRightIcon className="w-4 h-4 mr-1" />
                      {c.catName}
                    </Link>
                  </li>
                ))}
              </ul>
              <div className="text-base md:text-lg mt-5 mb-3">関連リンク</div>
              <ul className="space-y-1">
                {links.map((link, index) => (
                  <li key={index}>
                    <a
                      href={link.url}
                      className="flex items-center hover:text-blue-500"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <ChevronRightIcon className="w-4 h-4 mr-1" />
                      {link.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="my-5 md:my-10">
          <div>© {year} 水野コンサルタンシーホールディングス</div>
          {(location.hostname === "www.mizuno-ch.cn" ||
            location.hostname === "localhost") && <Icp />}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
